import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";

const SubscriptionBasketSize = () => {
  const basketSizeList = [
    { id: "single", title: "Single basket" },
    { id: "double", title: "Double basket" },
    { id: "other", title: "I know exactly how much coffee I use" },
  ];

  const [modelId] = useState(localStorage.getItem("subscriptionModelId"));
  const [basketSize, setBasketSize] = useState(
    localStorage.getItem("subscriptionBasketSize")
  );
  const navigate = useNavigate();

  if (!modelId) {
    navigate("/subscription/model");
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionBasketSize", basketSize);
    navigate("/subscription/cups-per-day");
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Your machine</h2>

        <form onSubmit={handleFormSubmission}>
          <div>
            <div className="mb-3">
              <p>What basket do you use?</p>
              {basketSizeList.map((item) => (
                <div key={item.id} className="form-check">
                  <input
                    name="basketSize"
                    id={`basketSize-${item.id}`}
                    className="form-check-input"
                    type="radio"
                    value={item.id}
                    checked={item.id === basketSize}
                    onChange={(e) => {
                      setBasketSize(e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`basketSize-${item.id}`}
                  >
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/model"
            buttonNextEnabled={basketSize && basketSize.length > 0}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionBasketSize;
