import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import SubscriptionLocation from "./Subscription/SubscriptionLocation";
import SubscriptionBrand from "./Subscription/SubscriptionBrand";
import SubscriptionModel from "./Subscription/SubscriptionModel";
import SubscriptionBasketSize from "./Subscription/SubscriptionBasketSize";
import SubscriptionCupsPerDay from "./Subscription/SubscriptionCupsPerDay";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";
import SubscriptionSteamer from "./Subscription/SubscriptionSteamer";
import SubscriptionOptions from "./Subscription/SubscriptionOptions";
import SubscriptionCoffee from "./Subscription/SubscriptionCoffee";
import SubscriptionSummary from "./Subscription/SubscriptionSummary";

const App = () => {
  return (
    <StrictMode>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/subscription/location"
            element={<SubscriptionLocation />}
          />
          <Route path="/subscription/brand" element={<SubscriptionBrand />} />
          <Route path="/subscription/model" element={<SubscriptionModel />} />
          <Route
            path="/subscription/basket-size"
            element={<SubscriptionBasketSize />}
          />
          <Route
            path="/subscription/cups-per-day"
            element={<SubscriptionCupsPerDay />}
          />
          <Route
            path="/subscription/steamer"
            element={<SubscriptionSteamer />}
          />
          <Route
            path="/subscription/subscription-options"
            element={<SubscriptionOptions />}
          />
          <Route
            path="/subscription/coffee-preference"
            element={<SubscriptionCoffee />}
          />
          <Route
            path="/subscription/summary"
            element={<SubscriptionSummary />}
          />
          <Route
            path="/subscription/"
            element={<Navigate to="/subscription/location" />}
          />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
