import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";

const SubscriptionOptions = () => {
  const cupsPerDayList = [
    { id: "1", title: "Maintenance only" },
    { id: "2", title: "Coffee only" },
    { id: "3", title: "Both" },
  ];

  const [steamer] = useState(localStorage.getItem("subscriptionSteamer"));
  const [preferredOption, setPreferredOption] = useState(
    localStorage.getItem("subscriptionPreferredOption")
  );
  const navigate = useNavigate();

  if (!steamer) {
    navigate("/subscription/steamer");
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionPreferredOption", preferredOption);
    navigate("/subscription/coffee-preference");
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Subscription options</h2>

        <form onSubmit={handleFormSubmission}>
          <div>
            <div className="mb-3">
              <p>What Subscriptions are you interested in?</p>
              {cupsPerDayList.map((item) => (
                <div key={item.id} className="form-check">
                  <input
                    name="preferredOption"
                    id={`preferredOption-${item.id}`}
                    className="form-check-input"
                    type="radio"
                    value={item.id}
                    checked={item.id === preferredOption}
                    onChange={(e) => {
                      setPreferredOption(e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`preferredOption-${item.id}`}
                  >
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/steamer"
            buttonNextEnabled={preferredOption}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionOptions;
