import { useState, useEffect } from "react";
import { json, Link, useNavigate } from "react-router-dom";
// import * as Icon from 'react-bootstrap-icons';
import SubscriptionNavigation from "./SubscriptionNavigation";
import DataService from "../DataService";
import EditLink from "./EditLink";

const SubscriptionSummary = () => {
  const [location] = useState(localStorage.getItem("subscriptionLocation"));
  const [brand] = useState(localStorage.getItem("subscriptionBrand"));
  const [modelId] = useState(localStorage.getItem("subscriptionModelId"));
  const [model, setModel] = useState({});
  const [productsRequired, setProductsRequired] = useState({});
  const [basketSize] = useState(localStorage.getItem("subscriptionBasketSize"));
  const [cupsADay] = useState(localStorage.getItem("subscriptionCupsADay"));
  const [steamer] = useState(localStorage.getItem("subscriptionSteamer"));
  const [preferredOption] = useState(
    localStorage.getItem("subscriptionPreferredOption")
  );
  const [preferredCoffee] = useState(
    localStorage.getItem("subscriptionPreferredCoffee")
  );

  useEffect(() => {
    async function fetchData() {
      setModel(await DataService.getMachinesById(modelId));
    }
    fetchData();
  }, [modelId]);

  useEffect(() => {
    async function fetchData() {
      setProductsRequired(
        await DataService.getProductsRequired(
          modelId,
          Number("1"),
          null,
          Number(cupsADay),
          Number(steamer)
        )
      );
    }
    fetchData();
  }, [modelId, cupsADay, steamer]);

  const navigate = useNavigate();

  // if (!preferredOption) {
  //   navigate("/subscription/subscription-options");
  // }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionPreferredCoffee", preferredCoffee);
    navigate("/subscription/summary");
  };

  return (
    <div className="container">
      <div className="col-8 mx-auto">
        <h2>Summary</h2>

        <form onSubmit={handleFormSubmission}>
          <div className="row mb-3">
            <div className="col-8">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={3} />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Your location</th>
                    <td>{location}</td>
                    <td>
                      <EditLink to="/subscription/location" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your machine</th>
                    <td>{model.name}</td>
                    <td>
                      <EditLink to="/subscription/model" />
                    </td>
                  </tr>
                  {basketSize && (
                    <tr>
                      <th scope="row">Your basket size</th>
                      <td>{basketSize}</td>
                      <td>
                        <EditLink to="/subscription/basket-size" />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Your cups a day</th>
                    <td>{cupsADay}</td>
                    <td>
                      <EditLink to="/subscription/cups-per-day" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your steamer usage</th>
                    <td>{steamer}</td>
                    <td>
                      <EditLink to="/subscription/steamer" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your preferred option</th>
                    <td>{preferredOption}</td>
                    <td>
                      <EditLink to="/subscription/subscription-options" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your preferred coffee</th>
                    <td>{preferredCoffee}</td>
                    <td>
                      <EditLink to="/subscription/coffee-preference" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4">
              {model && model.picture && model.name && (
                <div className="card">
                  <img
                    src={model.picture}
                    className="card-img-top"
                    alt={model.name}
                  />
                  <div className="card-body">
                    <p className="card-text">
                      <strong>{model.name}</strong>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-8">
              <h2>Your products</h2>
              <pre>{JSON.stringify(productsRequired)}</pre>
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/subscription-options"
            buttonNextVisible={false}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionSummary;
