import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";
import DataService from "../DataService";

const SubscriptionModel = () => {
  const [modelList, setModelList] = useState([]);
  const [brand] = useState(localStorage.getItem("subscriptionBrand"));
  const [model, setModel] = useState({});
  const [modelId, setModelId] = useState(
    localStorage.getItem("subscriptionModelId")
  );
  const [nameFilter, setNameFilter] = useState("");

  const navigate = useNavigate();

  if (!brand) {
    navigate("/subscription/brand");
  }

  useEffect(() => {
    async function fetchData() {
      setModelList(await DataService.getMachinesByBrand(brand));
    }
    fetchData();
  }, [brand]);

  useEffect(() => {
    async function fetchData() {
      setModel(await DataService.getMachinesById(modelId));
    }
    fetchData();
  }, [modelId]);

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionModelId", modelId);

    if (model && model.category === "Manual") {
      navigate("/subscription/basket-size");
    } else {
      navigate("/subscription/cups-per-day");
    }
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Your {brand} machine</h2>

        <form onSubmit={handleFormSubmission}>
          <div className="row mb-3">
            <div className="col-8">
              <p>Please Select the model of your {brand} coffee maker</p>
              <div className="mb-3">
                <label htmlFor="nameFilter" className="form-label">
                  Filter:
                </label>
                <input
                  type="text"
                  value={nameFilter}
                  className="form-control"
                  id="nameFilter"
                  placeholder="Model"
                  onChange={(e) => {
                    setNameFilter(e.target.value);
                    //setModelId("");
                  }}
                  onBlur={(e) => {
                    setNameFilter(e.target.value);
                    //setModelId("");
                  }}
                />
              </div>

              {modelList &&
                Array.isArray(modelList) &&
                modelList.map(
                  (item) =>
                    (!nameFilter ||
                      (item.id && item.id.toString() === modelId) ||
                      (item.name &&
                        item.name
                          .toLowerCase()
                          .indexOf(nameFilter.toLowerCase()) >= 0)) && (
                      <div key={item.id} className="form-check">
                        <input
                          name="model"
                          id={`model-${item.id}`}
                          className="form-check-input"
                          type="radio"
                          value={item.id}
                          checked={item.id.toString() === modelId}
                          onChange={(e) => {
                            setModelId(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`model-${item.id}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    )
                )}
            </div>
            <div className="col-4">
              {model && model.picture && model.name && (
                <div className="card">
                  <img
                    src={model.picture}
                    className="card-img-top"
                    alt={model.name}
                  />
                  <div className="card-body">
                    <p className="card-text">
                      <strong>{model.name}</strong>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/brand"
            buttonNextEnabled={modelId !== ""}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionModel;
