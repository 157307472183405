import { Component } from "react";

const localDataServiceCache = {};

class DataService extends Component {
  static apiBaseUrl = process.env.SUBSCRIPTION_API_BASEURL;

  static getAllMachines = async () => {
    if (
      localDataServiceCache.machines &&
      Array.isArray(localDataServiceCache.machines) &&
      localDataServiceCache.machines.length > 0
    ) {
      return localDataServiceCache.machines;
    }

    const results = await fetch(this.apiBaseUrl + "/machines/getall");
    const machines = await results.json();

    localDataServiceCache.machines = machines;

    return machines;
  };

  static getMachinesByBrand = async (brand) => {
    if (!brand) {
      return [];
    }
    const results = await fetch(
      `${this.apiBaseUrl}/machines/getbybrand?brand=${brand}`
    );
    const machines = await results.json();

    //return machines.filter((item) => item.brand === brand);
    return machines;
  };

  static getMachinesById = async (id) => {
    if (!id) {
      return null;
    }

    const results = await fetch(`${this.apiBaseUrl}/machines/getbyid?id=${id}`);
    const item = await results.json();
    return item;

    //const items = machines.filter((item) => item.id.toString() === id);
    // if (items && items.length >= 1) {
    //   return items[0];
    // }

    //return null;
  };

  static getProductsRequired = async (
    machineCategoryId,
    basketType,
    basketTypeCustom,
    cupsPerDay,
    frotherUsage
  ) => {
    const requestData = {
      machineCategoryId: machineCategoryId,
      basketType: basketType,
      basketTypeCustom: basketTypeCustom,
      cupsPerDay: cupsPerDay,
      frotherUsage: frotherUsage,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const results = await fetch(
      `${this.apiBaseUrl}/productsrequired`,
      requestOptions
    );
    const item = await results.json();

    return item ? item.data : null;

    //const items = machines.filter((item) => item.id.toString() === id);
    // if (items && items.length >= 1) {
    //   return items[0];
    // }

    //return null;
  };

  render() {
    return <></>;
  }
}

export default DataService;
