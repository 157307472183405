import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";

const SubscriptionBrand = () => {
  const brandList = [
    { id: "Gaggia", title: "Gaggia" },
    { id: "Saeco", title: "Saeco" },
    { id: "Philips", title: "Philips" },
    { id: "Delonghi", title: "Delonghi" },
  ];

  const [location] = useState(localStorage.getItem("subscriptionLocation"));
  const [brand, setBrand] = useState(localStorage.getItem("subscriptionBrand"));
  const navigate = useNavigate();

  if (!location) {
    navigate("/subscription/location");
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionBrand", brand);
    navigate("/subscription/model");
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Your machine</h2>

        <form onSubmit={handleFormSubmission}>
          <div>
            <div className="mb-3">
              <p>Please Select the brand of your coffee maker</p>
              {brandList.map((item) => (
                <div key={item.id} className="form-check">
                  <input
                    name="brand"
                    id={`brand-${item.id}`}
                    className="form-check-input"
                    type="radio"
                    value={item.id}
                    checked={item.id === brand}
                    onChange={(e) => {
                      setBrand(e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`brand-${item.id}`}
                  >
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/location"
            buttonNextEnabled={brand !== ""}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionBrand;
