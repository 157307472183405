import { useNavigate } from "react-router-dom";

const SubscriptionNavigation = (props) => {
  const getDefaultIfUndefined = function (value, defaultValue) {
    return value !== undefined ? value : defaultValue;
  };

  const buttonBackVisible = getDefaultIfUndefined(
    props.buttonBackVisible,
    true
  );
  const buttonBackEnabled = getDefaultIfUndefined(
    props.buttonBackEnabled,
    true
  );
  const buttonBackUrl = getDefaultIfUndefined(
    props.buttonBackUrl,
    "/subscription"
  );
  const buttonNextVisible = getDefaultIfUndefined(
    props.buttonNextVisible,
    true
  );
  const buttonNextEnabled = getDefaultIfUndefined(
    props.buttonNextEnabled,
    true
  );

  const navigate = useNavigate();

  const handleBackButtonClick = (e) => {
    navigate(buttonBackUrl);
  };

  return (
    <div className="my-8">
      <hr />
      {buttonBackVisible && (
        <button
          type="button"
          className="btn btn-secondary me-5"
          disabled={!buttonBackEnabled}
          onClick={handleBackButtonClick}
        >
          Back
        </button>
      )}
      {buttonNextVisible && (
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!buttonNextEnabled}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default SubscriptionNavigation;
