import { Link } from "react-router-dom";
import { Pen } from "react-bootstrap-icons";

const EditLink = (props) => {
  const to = props.to ? props.to : "/";
  const text = props.text ? props.text : "edit";

  return (
    <Link to={to}>
      <Pen />
      {text}
    </Link>
  );
};

export default EditLink;
