import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";

const SubscriptionCupsPerDay = () => {
  const cupsPerDayList = [
    { id: "1", title: "1 cup a day" },
    { id: "2", title: "2 cups a day" },
    { id: "3", title: "3 cups a day" },
    { id: "4", title: "4 cups a day" },
    { id: "5", title: "5 cups a day" },
    { id: "6", title: "6 cups a day" },
    { id: "7", title: "7 cups a day" },
    { id: "8", title: "8 cups a day" },
    { id: "9", title: "9 cups a day" },
    { id: "10", title: "10 cups a day" },
  ];

  const [modelId] = useState(localStorage.getItem("subscriptionModelId"));
  const [cupsADay, setCupsADay] = useState(
    localStorage.getItem("subscriptionCupsADay")
  );
  const navigate = useNavigate();

  if (!modelId) {
    navigate("/subscription/model");
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionCupsADay", cupsADay);
    navigate("/subscription/steamer");
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Your machine</h2>

        <form onSubmit={handleFormSubmission}>
          <div>
            <div className="mb-3">
              <p>How many cups of coffee do you brew with your coffee maker?</p>
              {cupsPerDayList.map((item) => (
                <div key={item.id} className="form-check">
                  <input
                    name="cupsADay"
                    id={`cupsADay-${item.id}`}
                    className="form-check-input"
                    type="radio"
                    value={item.id}
                    checked={item.id === cupsADay}
                    onChange={(e) => {
                      setCupsADay(e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`cupsADay-${item.id}`}
                  >
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <SubscriptionNavigation
            buttonBackUrl="/subscription/model"
            buttonNextEnabled={cupsADay}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionCupsPerDay;
