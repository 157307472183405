import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionNavigation from "./SubscriptionNavigation";

const SubscriptionLocation = () => {
  const locationList = [
    { id: "uk", title: "United Kingdom" },
    { id: "france", title: "France" },
    { id: "belgium", title: "Belgium" },
    { id: "other", title: "Other" },
  ];

  const [location, setLocation] = useState(
    localStorage.getItem("subscriptionLocation")
  );
  const [locationOther, setLocationOther] = useState("");
  const [locationCombined, setLocationCombined] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLocationCombined(
      location === "other" ? `${location} - ${locationOther}` : location
    );
  }, [location, locationOther]);

  const handleFormSubmission = (e) => {
    e.preventDefault();
    localStorage.setItem("subscriptionLocation", location);
    navigate("/subscription/brand");
  };

  return (
    <div className="container">
      <div className="col-6 mx-auto">
        <h2>Your location</h2>

        <form onSubmit={handleFormSubmission}>
          <div>
            <div className="mb-3">
              <p>Where are you from?</p>
              {locationList.map((item) => (
                <div key={item.id} className="form-check">
                  <input
                    name="location"
                    id={`location-${item.id}`}
                    className="form-check-input"
                    type="radio"
                    value={item.id}
                    checked={item.id === location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`location-${item.id}`}
                  >
                    {item.title}
                  </label>
                </div>
              ))}
            </div>

            {location === "other" && (
              <div className="mb-3">
                <label htmlFor="locationOther" className="form-label">
                  Please specify
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="locationOther"
                  placeholder="Country"
                  value={locationOther}
                  onChange={(e) => {
                    setLocationOther(e.target.value);
                  }}
                  onBlur={(e) => {
                    setLocationOther(e.target.value);
                  }}
                />
              </div>
            )}

            {location && location !== "uk" && (
              <div className="alert alert-danger" role="alert">
                At the moment We only provide a subscription service to customer
                resides in the United Kingdom
              </div>
            )}
          </div>

          <SubscriptionNavigation
            buttonBackVisible={false}
            buttonNextEnabled={locationCombined === "uk"}
          />
        </form>
      </div>
    </div>
  );
};

export default SubscriptionLocation;
